import baseURL from '@/static/baseURL'
import Cookies from 'universal-cookie'  

const cookies = new Cookies()

const state = {
    DT: [],
    error: null,
    isLoading: false
};

const mutations = {
    UPD_DT(state, data) {
        state.DT = {...state.DT, ...data}
        state.error = null
        state.isLoading = false
    },
    SUCCESS(state) {
        state.isLoading = false
    },
    LOADING(state) {
        state.isLoading = true
    },
    ERROR(state, error) {
        state.error = error
    },
    RESETERROR(state){
        state.error = null
    }
}; 

const actions = {
    getDataList({ commit }, params) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true })
            try {
                const config = { params }
                const {data} = await baseURL.get('api/settings/procat/json', config)  
                commit('UPD_DT', data);
                commit('TOGGLE_LOADING', null, { root: true })
                resolve(data)
            } catch(err){
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', err.response.data.message)
                reject()
            }
        })
    },  
    getDataLayout1({ commit }, params) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true })
            try {
                const config = { params }
                const {data} = await baseURL.get('api/settings/product/layout1/json', config)  
                commit('UPD_DT', data);
                commit('TOGGLE_LOADING', null, { root: true })
                resolve(data)
            } catch(err){
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', err.response.data.message)
                reject()
            }
        })
    },  
    getDataLayout2({ commit }, params) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true })
            try {
                const config = { params }
                const {data} = await baseURL.get('api/settings/product/layout2/json', config)  
                commit('UPD_DT', data);
                commit('TOGGLE_LOADING', null, { root: true })
                resolve(data)
            } catch(err){
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', err.response.data.message)
                reject()
            }
        })
    },  
    getDataLayout3({ commit }, params) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true })
            try {
                const config = { params }
                const {data} = await baseURL.get('api/settings/product/layout3/json', config)  
                commit('UPD_DT', data);
                commit('TOGGLE_LOADING', null, { root: true })
                resolve(data)
            } catch(err){
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', err.response.data.message)
                reject()
            }
        })
    },  
    getDataLayout4({ commit }, params) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true })
            try {
                const config = { params }
                const {data} = await baseURL.get('api/settings/product/layout4/json', config)  
                commit('UPD_DT', data);
                commit('TOGGLE_LOADING', null, { root: true })
                resolve(data)
            } catch(err){
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', err.response.data.message)
                reject()
            }
        })
    },  
    getDataLayout5({ commit }, params) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true })
            try {
                const config = { params }
                const {data} = await baseURL.get('api/settings/product/layout5/json', config)  
                commit('UPD_DT', data);
                commit('TOGGLE_LOADING', null, { root: true })
                resolve(data)
            } catch(err){
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', err.response.data.message)
                reject()
            }
        })
    },  
    getDataLayout6({ commit }, params) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true })
            try {
                const config = { params }
                const {data} = await baseURL.get('api/settings/product/layout6/json', config)  
                commit('UPD_DT', data);
                commit('TOGGLE_LOADING', null, { root: true })
                resolve(data)
            } catch(err){
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', err.response.data.message)
                reject()
            }
        })
    },  
    getDataLayout7({ commit }, params) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true })
            try {
                const config = { params }
                let data = await baseURL.get('api/settings/product/layout7_1/json', config)  
                let datas = {
                    section_1: data.data,
                }
                data = await baseURL.get('api/settings/product/layout7_2/json', config)  
                datas = {...datas, section_2 : data.data}
                commit('UPD_DT', datas);
                commit('TOGGLE_LOADING', null, { root: true })
                resolve(datas)
            } catch(err){
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', err.response)
                reject()
            }
        })
    },  
}

export default {
    namespaced: true,
    state,
    mutations,
    actions, 
}