const state = { 
    isLoading: false
};

const mutations = { 
    TOGGLE_LOADING(state) {
        state.isLoading = !state.isLoading
    }, 
};

const getters = {
    isLoading(state){
        return state.isLoading
    }
}

export default { 
    state, 
    getters,
    mutations
}