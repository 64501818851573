import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/home.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import('@/views/contacts.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/insight/:type/:id',
      name: 'insightDetail',
      component: () => import('@/views/insight.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/insight',
      name: 'insight',
      component: () => import('@/views/insight.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/about-us',
      name: 'aboutUs',
      component: () => import('@/views/about.vue'),
      meta: {
        layout: 'full',
      },
    }, 
    {
      path: '/product-services/layout1/:id',
      component: () => import('@/views/products/layout1.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/product-services/layout2/:id',
      component: () => import('@/views/products/layout2.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/product-services/layout3/:id',
      component: () => import('@/views/products/layout3.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/product-services/layout4/:id',
      component: () => import('@/views/products/layout4.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/product-services/layout5/:id',
      component: () => import('@/views/products/layout5.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/product-services/layout6/:id',
      component: () => import('@/views/products/layout6.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/product-services/layout7/:id',
      component: () => import('@/views/products/layout7.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/product-services/change-management',
      name: 'changeManagementProd',
      component: () => import('@/views/product_service/change_management.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/product-services/:id',
      name: 'productServicesLevel3',
      component: () => import('@/views/product_services.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/events',
      name: 'events',
      component: () => import('@/views/events.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/events/:id',
      name: 'events-detail',
      component: () => import('@/views/event_detail.vue'),
      meta: {
        layout: 'full',
      },
    }, 
    {
      path: '/team',
      name: 'team',
      component: () => import('@/views/team.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/team/:id',
      name: 'team-detail',
      component: () => import('@/views/team_detail.vue'),
      meta: {
        layout: 'full',
      },
    }, 
    {
      path: '/under-maintanance',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
