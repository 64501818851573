import baseURL from '@/static/baseURL'
import Cookies from 'universal-cookie'  

const cookies = new Cookies()

const state = {
    DT: [],
    CURRENT: 'EN',
    error: null,
    isLoading: false
};

const mutations = {
    UPD_DT(state, data) {
        state.DT = data
        state.error = null
        state.isLoading = false
    },
    UPD_CURRENT(state, data) {
        state.CURRENT = data
        state.error = null
        state.isLoading = false
    },
    SUCCESS(state) {
        state.isLoading = false
    },
    LOADING(state) {
        state.isLoading = true
    },
    ERROR(state, error) {
        state.error = error
    },
    RESETERROR(state){
        state.error = null
    }
}; 

const actions = {
    changeLanguage({commit}, lang) {
        commit('LOADING')
        cookies.set('lang', lang, { path: '/' })
        commit('UPD_CURRENT', lang)
    },
    getAllLang({commit}) {
        return new Promise(async (resolve, reject) => {
            commit('TOGGLE_LOADING', null, { root: true })
            try { 
                const {data} = await baseURL.get('api/settings/languages/json')  
                commit('UPD_DT', data);
                commit('TOGGLE_LOADING', null, { root: true })
                resolve(data)
            } catch(err){
                commit('TOGGLE_LOADING', null, { root: true })
                commit('ERROR', err.response?.data.message)
                reject()
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions, 
}