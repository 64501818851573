import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Multiselect from 'vue-multiselect'
import router from './router'
import store from './store'
import App from './App.vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// Global Components
import './global-components'
import VueNoty from 'vuejs-noty'
import 'vuejs-noty/dist/vuejs-noty.css'
import 'swiper/css/swiper.css'
import Fitty from 'vue-fitty' 

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import VueVideoPlayer from 'vue-video-player'
import infiniteScroll from 'vue-infinite-scroll'

// require videojs style
import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
Vue.use(Fitty)

Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)
Vue.use(VueQuillEditor)
// BSV Plugin Registration 
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.component('multiselect', Multiselect) 
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(infiniteScroll)
Vue.use(VueNoty)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
