import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import loading from './loading'
import articles from './articles'
import soundboards from './soundboards'
import genres from './genres'
import events from './events'
import page_content from './page_content'
import locales from './locales'
import clients from './clients'
import teams from './teams'
import contacts from './contacts'
import product_categories from './product_categories'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    loading, soundboards,
    articles, genres, events, page_content, locales, clients, contacts, product_categories, teams,

    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
